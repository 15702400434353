.two-factor-main {
    background-color: #1A253A;
    min-height: 100vh;
    color: white;
    font-size: 12px;
    padding-top: 10%;
}
.two-factor-content-div {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}
.two-factor-code-input-header {
    margin-bottom: 30px;
}
.two-factor-verify-btn {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 30%;
}