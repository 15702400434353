.mfa-modal-qr-image {
    width: 350px;
}
.mfa-modal-header {
    justify-content: space-around;
}
.mfa-modal-header-div-main {
    font-weight: bold;
}
.mfa-modal-body-div-main {
    display: flex;
    flex-direction: column;
}
.mfa-modal-verify-btn {
    margin-top: 10px;
    background-color: #87CEFA;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mfa-modal-body-spinner {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}
.mfa-modal-code-input-header {
    text-align: center;
    font-weight: bold;
}
.mfa-modal-explanation-input-code {
    margin-top: 10px;
    text-align: center;
}
.mfa-modal-Manual {
    word-wrap: break-word;
}
.mfa-modal-qr-Manual-explanation {
    font-weight: bold;
    text-align: center;
}