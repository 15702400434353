.personal-user-settings-modal-header {
    display: flex;
}
.personal-user-settings-modal-logo {
    width: 9.25rem;
    height: 3.063rem;
}
.personal-user-settings-modal-header-text {
    font-size: 22px;
    color: #3c3f4a;
    margin-left: 1rem;
    transform: translateY(25%)
}
.personal-user-settings-modal-details {
    display: flex;
    height: 3.125rem;
    border-radius: 5px;
    border: solid 1px rgba(26, 37, 58, 0.06);
    background-color: #fbfbfb;
    padding-left: 0.938rem;
    margin-bottom: 0.5rem;
    /* padding: 0.5rem 0.938rem 0.563rem 0.938rem; */
}
.personal-user-settings-modal-details-input {
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
    outline: none;
    height: 2.313rem;
    max-width: 11.125rem;
    padding-left: 0.5rem;
    font-size: 14px;
    color: rgba(60, 63, 74, 0.6);
    margin-top: 0.4rem;
    margin-left: 0.3rem;
}
.personal-user-settings-modal-details-email {
    font-size: 14px;
    color: #3c3f4a;
    width: 36%;
    margin-top: 0.8rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0.2rem;
}
.personal-user-settings-modal-details-name {
    font-size: 14px;
    color: #3c3f4a;
    width: 36%;
    margin-top: 0.8rem;
}
.personal-user-settings-modal-details-headers {
    display: flex;
    font-size: 12px;
    color: rgba(60, 63, 74, 0.6);
    padding-left: 0.938rem;
    margin-bottom: 0.5rem;
}
.personal-user-settings-modal-details-header-email {
    width: 36%;
}
.personal-user-settings-modal-details-header-full-name {
    width: 36%;
}
.personal-user-settings-modal-details-main {
    max-height: 10.5rem;
    overflow-y: auto;
    position: relative;
}
.personal-user-settings-modal-details-main::-webkit-scrollbar {
    width: 0.25em;
}
.personal-user-settings-modal-details-main::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
}
.personal-user-settings-modal-details-main::-webkit-scrollbar-thumb {
    background-color: #e3e5e7;
    border-radius: 5px;
}

.personal-user-settings-loader {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.personal-user-settings-floating-loader {
    position: absolute;
    width: 77px;
    top: 1px;
    left: 50%;
}
.personal-user-settings-modal-details-inputs {
    display: flex;
}
.personal-user-settings-modal-details-dynamic-header {
    width: 28%;
}
.personal-user-settings-modal-details-dynamic-active-header {
    width: 16%;
}
.personal-user-settings-modal-details-checkbox {
    margin-top: auto;
    margin-bottom: auto;
    width: 6rem;
}
.personal-user-settings-modal-details-active-checkbox {
    text-align: center;
}