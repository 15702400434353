.ddb-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    /* height: 93px; */
    width: 320px;
    border: 1px solid rgba(36,49,63,0.12);
    border-radius: 5px;
    background-color: #FFFFFF;
    margin: 10px;
    padding: 15px;
    font-size: 14px;
    font-weight: bold;
    color:  rgba(60,63,74,0.6);;
}
.ddb-call-active-text {
    opacity: 0;
    transition: opacity 2s;
}
.ddb-did-num {
    margin-top: 4px;
    margin-bottom: 4px;
}
.ddb-marg-text-from-icon {
    margin-left: 10px;
}