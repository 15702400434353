.st-table-main {
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
    background-color: #ffffff;
}
.st-table-row {
    display: flex;
    /* height: 3.75rem; */
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
    padding-top: 1.25rem;
}
.st-table-username-cell {
    overflow-wrap: break-word;
}
.st-table-header {
    display: flex;
    background-color: #fbfbfb;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
    min-height: 2.25rem;
    padding-left: 2.75rem;
    padding-top: 0.5rem;
}