        .cursorPointer {
            cursor: pointer !important;
        }
        
        .btnBorder {
            border: 1px solid #2EC2FE;
        }
        
        .btnBorderButtom {
            border-bottom: 1px solid #2EC2FE;
        }
        
        .borderButtom {
            border-bottom: 1px solid #ECE7E7;
        }
        
        .btnColor1 {
            background-color: rgb(207, 200, 200);
        }
        
        .btnColor2 {
            background-color: #2EC2FE!important;
        }
        
        .btnColor3 {
            background-color: #44d551;
        }
        
        .btnColor4 {
            background-color: #e1e2e4;
        }
        
        .btnColor5 {
            background-color: #ebebeb;
        }
        
        .canceled {
            background-color: #f7cac93a;
        }
        
        .white {
            background-color: #ffffff;
        }
        
        .cursorDef {
            cursor: default !important;
        }
        
        .gray {
            color: rgba(60, 63, 74, 0.6) !important;
            font-family: Poppins, serif;
            font-weight: bold;
        }
        
        .border-5 {
            border-radius: 5px;
        }
        
        .Rectangle {
            border-radius: 5px;
            border: solid 1px rgba(255, 255, 255, 0.96);
            background-color: rgba(255, 255, 255, 0.05);
            text-align: center;
        }
        
        .dropItems {
            background-color: #ebebeb;
            border-radius: 5px;
        }
        
        .fundsModal>.modal-content {
            flex: 0 0 75%;
            max-width: 75%;
            font-family: 'Poppins', sans-serif !important;
            margin-top: 200px !important;
            padding: 0.563rem;
        }
        
        .customDatePickerWidth,
        .customDatePickerWidth>div.react-datepicker-wrapper,
        .customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
            width: 100%;
        }
        
        .widthModal>.modal-title {
            width: 100vw !important;
        }
        
        .paymentModal {
            max-width: 1400px;
        }
        
        .paymentContantModal>.modal-content {
            width: 1400px;
        }

        .loginGroupModal {
            max-width: 1000px;
        }
        
        .loginGroupModal>.modal-content {
            width: 1000px;
        }
        
        .iframeModal>.modal-content {
            width: 1002px;
        }
        
        .lableFont {
            color: rgba(60, 63, 74, 0.49);
        }
        
        .fontColor {
            color: #3C3F4A;
            font-family: 'Poppins', sans-serif !important;
        }
        
        .modal-contant {
            font-family: 'Poppins', sans-serif !important;
        }
        
        .no-border {
            border: none;
        }
        
        .Will-be-deleted-on-2 {
            /* height: 17px;
    margin: 1px 0 0 0px; */
            font-size: 12px;
            color: rgba(233, 65, 65, 0.6);
        }
        
        .UserSpikko {
            /* height: 20px;
            margin: 1px 0 0 5px; */
            font-size: 14px;
            font-weight: bold;
            margin-left: -30px;
            color: #3c3f4a;
        }
        
        .table td,
        .table tr {
            padding-left: 0.75rem;
            vertical-align: inherit;
            border-bottom: 1px solid #dee2e6;
        }
        
        .block {
            display: block;
        }
        
        .paginationItem {
            border: 50px !important;
        }
        
        .tableBorder {
            border: 1px solid #eeeeee;
            border-radius: 5px;
        }
        
        .poppins-regular {
            font-family: 'Poppins', sans-serif;
        }
        
        .pagination-new {
            font-family: 'Poppins', sans-serif;
            color: #3C3F4A;
            caret-color: #44d551;
        }
        
        .aa {
            position: inherit;
        }
        
        .no-border2 {
            max-width: 130px;
            border: none;
        }
        
        .border-52 {
            border-radius: 5px;
            min-width: 180px;
        }
        
        .isolate-the-design .custom-control-input:checked~.custom-control-label::before {
            border-color: #34b640 !important;
            background-color: #34b640 !important;
            border-radius: 2rem;
            width: 37px;
            height: 24px;
        }
        
        .isolate-the-design .custom-control-input~.custom-control-label::before {
            border-color: #6d7381 !important;
            background-color: #6d7381 !important;
            width: 37px;
            border-radius: 2rem;
            height: 24px;
        }
        
        .custom-switch .custom-control-label::after {
            width: 22px;
            height: 20px;
            background-color: #9199ab;
            border-radius: 2rem;
        }
        
        tbody::-webkit-scrollbar {
            width: 4px;
        }
        
        tbody::-webkit-scrollbar-track {
            /*box-shadow: inset 10 0 6px rgba(0, 0, 0, 0.3);*/
        }
        
        tbody::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid #e3e5e7;
        }

        img.hover-zoom:hover {
            transform: scale(1.05);
            cursor: pointer;
        }

        .mini-package-main {
            border-radius: 5px;
            border: solid 1px rgba(36, 49, 63, 0.12);
            background-color: #ffffff;
            padding-bottom: 0.875rem;
            padding-right: 0.2rem;
        }
        .choose-template-btn {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .report-btn {
            background-color: #ebebeb;
        }
        .report-user-btn-line {
            border-left: 1px solid #FFFFFF;;
        }
        .report-user-sms-report-btn {
            padding-top: 5px;
        }
        .report-user-call-report-btn {
            padding-top: 3px;
        }
        .iphon-icon-messages-report-btn {
            margin-left: 2px;
        }
        .row-table-messages-report-iphone-icon {
            margin-left: 6px;
        }
        .row-table-reset-pass-modal {
            margin-top: 8%;
            max-width: 370px;
        }
        #dashboard_more_option_call_report {
            margin-left: 0.131rem;
        }
        #dashboard_more_option_show_payments {
            padding-left: 1.9rem;
        }
        #dashboard_more_option_open_user_page {
            margin-top: 0.6rem; 
            padding-left: 1.7rem;
        }
        #dashboard_more_option_edit_caller_ids {
            margin-left: 0.131rem;
        }
        #sms-report-icon {
            margin-left: 0.313rem;
        }
        #dashboard_btn_users_sms_report {
            margin-top: 0.125rem;
        }
