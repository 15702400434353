.sms-report-user-details-main {
    border:  solid 1px #ece7e7;
    display: flex;
    cursor: pointer;
}
.sms-report-user-details-user-icon-div {
    position: relative;
    margin-left: 1.188rem;
    margin-top: 7%;
    margin-bottom: 7%;
}
.sms-report-user-details-user-icon {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #44d551;
    top: 1.938rem;
    left: 1.75rem;
}
.sms-report-user-details-user-name {
    font-size: 14px;
    color: #3c3f4a;
}
.sms-report-user-details-date {
    font-size: 12px;
    color: #8a8c92;
    margin-top: 0.188rem;
}
.sms-report-user-details-around-date-and-name {
    display: flex;
    margin-top: 6%;
    margin-left: 3%;
    margin-right: 5%;
    justify-content: space-between;
}
.sms-report-user-details-right {
    width: 100%;
}
.sms-report-user-details-user-email {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
    margin-left: 3%;
    margin-top: 4%;
}