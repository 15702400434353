.users-sms-report-main-div {
    margin-left: 1.563rem;
    margin-right: 1.563rem;
    margin-top: 1.125rem;
}
.users-sms-report-above-main-div {
    display: flex;
}
.users-sms-report-above-main-text {
    font-size: 22px;
    color: #3c3f4a;
}
.users-sms-report-download-as-csv-button {
    min-width: 11.625rem;
    height: 2.375rem;
    margin-top: auto;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
}
.users-sms-report-back-btn {
    min-width: 6rem;
    height: 2.375rem;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}
.users-sms-report-arrow-left-icon {
    margin-right: 6px;
}
.users-sms-report-dashboard-main {
    border-radius: 5px;
    border: solid 1px rgba(26, 37, 58, 0.06);
}
.users-sms-report-main-headers {
    display: flex;
    padding-top: 1.25rem;
    padding-bottom: 1.313rem;
}
.users-sms-report-main-header-bold {
    font-size: 18px;
    font-weight: bold;
    color: #3c3f4a;
    display: flex;
    width: 65%;
}
.users-sms-report-main-header-number {
    font-size: 14px;
    color: #3c3f4a;
    display: flex;
    width: 35%;
    padding-top: 0.313rem;
}
.users-report-dashboard-details {
    display: flex;
    min-height: 43rem;
    max-height: 43rem;
}
/* Left column  */
.users-report-dashboard-users-list {
    border: solid 1px rgba(26, 37, 58, 0.06);
    width: 30%;
    overflow-y: auto;
}
.users-report-dashboard-users-list::-webkit-scrollbar {
    width: 0.375em;
}
.users-report-dashboard-users-list::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.users-report-dashboard-users-list::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
/* Middle column */
.users-report-dashboard-users-number {
    border: solid 1px rgba(26, 37, 58, 0.06);
    background-color:  #f5f5f5;
    width: 30%;
    overflow-y: auto;
}
.users-report-dashboard-users-number::-webkit-scrollbar {
    width: 0.375em;
}
.users-report-dashboard-users-number::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.users-report-dashboard-users-number::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
/* Right column */
.users-report-dashboard-users-messages {
    border: solid 1px rgba(26, 37, 58, 0.06);
    width: 40%;
    padding: 1.25rem;
    background-color: #e9e9e9;
    overflow-y: auto;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.users-report-dashboard-users-messages::-webkit-scrollbar {
    width: 0.375em;
}
.users-report-dashboard-users-messages::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.users-report-dashboard-users-messages::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
.users-sms-report-main-header-users-number {
    margin-left: 1.813rem;
    width: 46%;
}
.users-sms-report-main-header-icon{
    width: 9%;
}
.users-sms-report-main-header-conversations-number {
    width: 36%;
}
.users-sms-report-main-header-from-number {
    margin-left: 2rem;
    width: 40%;
}
.users-sms-report-main-header-to-number {
    cursor: pointer;
}
.users-sms-report-main-header-to-icon {
    width: 6%;
}
.user-sms-report-no-message {
    display: flex;
}
.user-sms-report-no-message-text {
    font-size: 18px;
    color: #3c3f4a;
    margin-left: auto;
    margin-right: auto;
}
#user-sms-report-next-to-users-messages {
    float: left;
    clear: both;
}
.users-sms-report-download-as-csv-spinner {
    margin-left: 0.4rem;
}
.usr-chat {
    width: 100%;
    max-width: 800px;
    height: calc(100vh - 50px);
    min-height: 100%;
    transform: rotate(180deg);
    direction: rtl;
}
.users-sms-report-above-main-date-inputs {
    display: flex;
    margin-left: auto;
}
.users-report-arrow-icon:hover {
    transform: scale(1.3);
    cursor: pointer;
}
.users-sms-report-popover {
    display: flex;
}
.users-sms-report-person-add-icon {
    margin-left: 20px;
    height: 20px;
}
.users-sms-report-person-add-icon:hover {
    cursor: pointer;
    transform: scale(1.3);
}