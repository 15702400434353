.data-cell-main {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 138px;
    width: 172px;
    border: 1px solid rgba(36,49,63,0.12);
    border-radius: 5px;
    background-color: #FFFFFF;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 25px;
    margin-bottom: 25px;
}
.data-cell-header {
    height: 40px;
    color: #3C3F4A;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 4px;
}
.data-cell-value-main {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}
.data-cell-value {
    height: 51px;
    color: #3C3F4A;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 55px;
}