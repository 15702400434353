.Rectangle2 {
    border-radius: 5px;
    border: solid 1px rgba(36,49,63,0.12);
    text-align: center;
  }
.cardBorder {
    border-radius: 5px;
    border: solid 1px rgba(26,37,58,0.06);
    padding: 10px;
  }
  .mfa-modal-main {
    margin-top: 8%;
    max-width: 382px;
  }