.reset-pass-modal-header {
    justify-content: space-around;
}
.reset-pass-submit-btn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    border: none;
}
.reset-pass-modal-header-username {
    font-weight: bold;
}