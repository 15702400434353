.user-conversations-main {
    width: 100%;
    height: 2.375rem;
    display: flex;
    border: solid 1px #e9e9e9;
    cursor: pointer;
    padding-top: 2%;
}
.user-conversations-icon {
    margin-left: 1.375rem;
}
.user-conversations-number {
    margin-left: 0.785rem;
    font-size: 14px;
    color: #3c3f4a;
}
.user-conversations-select-icon {
    margin-bottom: 0.4rem;
}