.group-msg-header-main {
    display: flex;
    justify-content: space-between;
    width: 35%;
}
.group-msg-header-group-name {
    color: #3C3F4A;
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27px;
}
.group-msg-header-group-numbers {
    color: #3C3F4A;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    margin-right: 2.25rem;
}
.group-msg-header-group-name-main {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
}
.group-msg-header-info-icon {
    margin-left: 2.25rem;
    margin-right: 1.313rem;
    cursor: pointer;
}
.group-msg-header-arrow-right-small {
    
}
.group-msg-header-group-name-icon {
    margin-left: 0.633rem;
}
.group-msg-header-pophover-group-name {
    color: #3C3F4A;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 0.5rem;
}
.group-msg-header-all-num-divider {
    border-bottom: 1px solid #ECE7E7;
    box-sizing: border-box;
    margin: 3px 15px 3px 0;
}