/* .flag-icon {
    width: 18px;
    height: 18px;
} */

@font-face {
    font-family: 'BabelStoneFlags';
    src: local('BabelStoneFlags'), url(../../fonts/BabelStoneFlags.ttf) format('truetype');
}
.ac-main {
    font-family: 'BabelStoneFlags';
}