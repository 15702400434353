.file-msg-main {
    display: flex;
    box-sizing: border-box;
    border: 1px solid #E9E9E9;
    border-radius: 6px;
    background-color: #FFFFFF;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    padding-left: 0.625rem;
}
.file-msg-main-hover:hover{
    cursor: pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.file-msg-details-main {
    margin-left: 0.563rem;
}
.file-msg-file-name {
    color: #3C3F4A;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 0.563rem;
}
.file-msg-file-details {
    color: #8A8C92;
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 0.5rem;
}
.file-msg-pdf-icon {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
.file-msg-docs-icon {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
.file-msg-image-icon {
    margin-top: 0.813rem;
    margin-bottom: 0.813rem;
}
.file-msg-modal-media {
    width: max-content;
    max-width: max-content;
}
.file-msg-image {
    max-width: 750px;
}
.file-msg-video {
    max-width: 750px;
}
.file-ms-x-icon {
    margin-left: auto;
    margin-right: 15px;
}