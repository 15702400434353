.sms-report-modal-header {
    text-align: center;
    margin-top: 1rem;
}
.sms-reprt-date-header-1 {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
}
.sms-reprt-date-header-2 {
    font-size: 14px;
    color: #3c3f4a;
}
.sms-report-date-header {
    font-size: 12px;
    color: #3c3f4a;
}
.sms-report-date-col {
    margin-top: 1rem;
    justify-content: space-between;
}
.sms-report-all-users-main {
    width: 24rem;
    height: 21.188rem;
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
    background-color: #ffffff;
    margin-top: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.188rem;
    padding-top: 1rem;
}
.sms-report-buttons-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1.938rem;
}
.sms-report-export-icon {
    margin-right: 0.563rem;
    margin-bottom: 0.3rem;
}
.sms-report-export-as-table-button {
    padding: 0.563rem 2.438rem 0.563rem 2.5rem;
}
.sms-report-download-as-csv-button {
    padding: 0.563rem 1.188rem;
}
.sms-report-user-list {
    height: 2.688rem;
    display: flex;
    flex-direction: column;
    padding-top: 0.688rem;
    padding-left: 0.2rem;
}
.sms-report-user-list-bottom-line {
    border-bottom: solid 0.5px #ece7e7;
    margin-top: auto;
    margin-right: 1.125rem;
}
.sms-report-user-list .custom-control-input:checked~.custom-control-label::before {
    border-color: #ece7e7 !important;
    background-color: #2ec2fe !important;
}
.sms-report-user-list .custom-control-input~.custom-control-label::before {
    border-color: #ece7e7 !important;
    background-color: #ffffff !important;
}
.sms-report-all-users-buttons-main {
    display: flex;
    justify-content: space-between;
}
.sms-report-all-users-button {
    border: none;
    background-color: #ffffff;
    font-family: Poppins;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
}
.sms-report-all-users-list {
    height: 13.875rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1.75rem;
}
.sms-report-all-users-list::-webkit-scrollbar {
    width: 0.375em;
}
.sms-report-all-users-list::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.sms-report-all-users-list::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
.react-datepicker-popper {
    z-index: 1000;
}
.sms-report-search-input {
    margin: 4px 20px 0 20px;
}