.mini-package-header {
    display: flex;
    padding-bottom: 0.5rem;
    margin-bottom: 1.25rem;
}
.mini-package-header-title {
    margin-top: 11px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
}
.mini-package-header-icon-local-num-title {
    margin-left:7%;
}
.mini-package-details-list {
    display: flex;
    height: 3rem;
    padding-top: 1rem;
}
.mini-package-details-list-font {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
}
.mini-package-list-bottom-line {
    border: solid 1px #e3e5e7;
    margin: 0.413rem 1.25rem 0.475rem;
}
.mini-package-edit-forward-to-icon{
    margin-left: 0.625rem;
    height: 1.2rem;
}
img.mini-package-edit-forward-to-icon:hover {
    transform: scale(1.05);
    cursor: pointer;
}
.mini-package-renew-now {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2ec2fe;
}
.mini-package-3-points-icon {
    height: 0.3rem;
}
img.mini-package-3-points-icon:hover {
    transform: scale(1.10);
    cursor: pointer;
}
.mini-package-usd {
    font-size: 14px;
    font-weight: 600;
    color: #3c3f4a;
}
img.mini-package-forward-to:hover {
    transform: scale(1.05);
    cursor: pointer;
}
.advanced-features-modal-btn-list {
    border-radius: 5px;
}
.advanced-features-modal-btn-list:hover {
    cursor: pointer;
    background-color: #f1f1f5;
}
.advanced-features-modal-header {
    font-family: Poppins;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
}
img.mini-package-expiry-date-not-set-icon:hover {
    transform: scale(1.05);
    cursor: pointer;
}
#advanced_features_dropdown_di_group_ringing {
    height: 2.625rem;
    margin-left: 0.6rem;
    margin-top: 0.4rem;
}
#advanced_features_dropdown_di_sequential_ringing {
    height: 2.625rem;
    margin-left: 0.6rem;
}