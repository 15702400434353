    .contactUsBody {
        height: 90vh;
        width: 100%;
        background-color: #1a253a;
        font-size: 12px;
    }
    
    .form {
        width: 60%;
        color: white;
    }
    
    .submit {
        width: 100%;
        background-color: #2ec2fe;
        font-size: 14px;
        font-weight: bold
    }
    
    .red {
        color: red;
    }
    
    /*.white {*/
    /*    color: white*/
    /*}*/

    .modalBody{
        position: fixed;
        height: 100vh;
        width: 75%;
    }