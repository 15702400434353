.user-conversations-details-main {
    border: solid 1px #ece7e7;
    background-color: #ffffff;
    cursor: pointer;
}
.user-conversations-details-from {
    display: flex;
    margin-top: 4%;
    margin-left: 7%;
}
.user-conversations-details-from-number-main {
    font-size: 14px;
    color: #3c3f4a;
    margin-left: 2%;
    display: flex;
    justify-content: space-between;
    width: 85%;
}
.user-conversations-details-from-number {
    font-weight: bold;
}
.user-conversations-details-to {
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-top: 3%;
    margin-left: 8%;
    margin-bottom: 3%;
}
.user-conversations-details-to-number {
    font-size: 14px;
    color: #3c3f4a;
    margin-left: 0.5rem;
}
.user-conversations-details-to-latest-time {
    font-size: 14px;
    color: #3c3f4a;
    margin-right: 4%;
}
.user-conversations-user-name-icon {
    height: 1.625rem;
    margin-left: 0.633rem;
}
.user-conversations-details-to-left-side {
    display: flex;
    align-items: center;
}