  .line-1 {
      width: 100%;
      background-color:#ece7e7;
      height:1px;
    }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .v-center {
    display: flex;
    align-items: center;
  }

  .progress{
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color:#e3e5e7 ;
  }
  .progress > div{
    height: 4px;
    border-radius: 4px;
    background-color:#2ec2fe ;
  }

  .fc-grey{
    color: rgba(60, 63, 74, 0.6)
  }

  .fs-10 {
    font-size: 10px;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-22 {
    font-size: 22px;
    color: #3c3f4a;
  }

  .center {
    vertical-align: middle!important;
  }

  .border-grey{
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
  }

  .box-yellow{
    border-radius: 5px;
    border: solid 1px #fffcea;
    padding-left: 25px;
  }

  .background-lightGrey{
    background-color: #fbfbfb;
    border-radius: 5px;
    padding: 15px;
  }

  .background-lightGrey-2 {
    background-color: #fbfbfb;
    border-radius: 5px;
    padding-left: 15px;
  }

  .light-blue{
    color:#2EC2FE
  }

  .light-red{
    color:#f89999
  }

  .pointer {
    cursor: pointer;
  }
  table {
    /*width: 100%;*/
  }

   .thead, .tbody, .tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .tr{
    /* height: 30px; */
    border: solid 1px rgba(26, 37, 58, 0.06);
    background-color: #fbfbfb;
    margin-bottom: 8px;
    border-radius:10px ;
  }
  .tr-disabled{
    background-color: rgb(243, 243, 243);
  }
  .tr-disabled > .h{
    color: rgb(160, 160, 160);
  }

  .tr-disabled > .disabled-row{
    opacity: 0.4;
  }

  .tr-enabled {
    background-color: #fbfbfb;
  }
  .tbody {
    display: block;
    overflow: auto;
    table-layout: fixed;

    border-collapse: separate;
    border-spacing: 0 ;
    /*max-height: 250px;*/
  }

  .oneLine{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }


  .ss{
    
    margin-right: 30px;
    padding-right: 80px;
  }


  .arrowDown:after{
    content: '';
    border: 4px solid transparent;
    border-top: 4px solid white;
    margin-left: 2px;
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: bottom;

  }
  .af-modal-header {
    font-family: Poppins;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
  }
  .af-modal-header-brackets {
    font-size: 18px;
    color: rgba(60, 63, 74, 0.6);
  }
  .country-select {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
  }
  .country-select-selected {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
  }
  .th-3-points {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .aaBody > tr { 
    border-radius: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
  }
  
  .aaBody > tr > td { 
    padding: 0;
    margin: 0;
  }

  .table .gr-mb > tr > th {
    padding-left: 0;
  }

  .sr-form {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .sr-form-label {
    color: #3c3f4a;
  }
  /* SequentialRinging */
   #sr-th-1 {
    width: 24%;
   }
   #sr-th-2 {
    width: 20%;
  }
  #sr-th-3 {
    width: 20%;
  }
  #sr-th-4 {
    width: 17%;
  }
  #sr-td-1 {
    width: 24%;
   }
   #sr-td-2 {
    width: 20%;
  }
  #sr-td-3 {
    width: 20%;
  }
  #sr-td-4 {
    width: 17%;
  }
  /* TimeForward */
  #tf-th-1 {
    width: 14%;
   }
   #tf-th-2 {
    width: 15%;
  }
  #tf-th-3 {
    width: 23%;
  }
  #tf-td-1 {
    width: 14%;
   }
   #tf-td-2 {
    width: 15%;
  }
  #tf-td-3 {
    width: 23%;
  }
  #tf-tr{
    padding-left: 0;
  }
  /* AutoAttendant */
  #aa-th-1 {
    width: 18%;
   }
   #aa-th-2 {
    width: 27%;
  }
  #aa-th-3 {
    width: 20%;
  }
  #aa-td-1 {
    width: 18%;
   }
   #aa-td-2 {
    width: 27%;
  }
  #aa-td-3 {
    width: 20%;
  }