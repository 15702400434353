.contact-list-main {
    width: 90%;
    margin-left: 30px;
    margin-right: 30px;
}
.contact-list-header {
    display: flex;
    /* justify-content: space-around; */
    border-bottom: 2px solid #1A253A;
    color: #1A253A;
    height: 50px;
    align-items: center;
}
.contact-list {
    display: flex;
    color: rgba(46, 48, 55, 0.7);
    height: 50px;
    align-items: center;
}

.contact-list:hover {
    box-shadow: 0 0.5rem 1.2rem rgb(0 0 0 / 20%);
}
.contacts-plus-icon {
    position: absolute;
    bottom: 60px;
    left: 140px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 50%;
}
.contacts-plus-icon:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.contact-list-row-edit-icon-span {
    width: 8%;
}
.contact-list-row-edit-icon:hover {
    cursor: pointer;
    transform: scale(1.3);
}
.contact-list-row-delete-icon:hover {
    cursor: pointer;
    transform: scale(1.3);
}
.contact-list-header-name {
    margin-left: 5%;
    width: 30%;
}
.contact-list-header-number {

}
.contact-list-row-name {
    margin-left: 5%;
    width: 30%;
}
.contact-list-row-number {
    width: 50%;
}
.pagination-div {
    display: flex;
    align-items: flex-end;
    margin-top: 30px;
}