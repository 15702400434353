.wrapper{
    background-color: #1A253A;
   min-height: 100vh;
    color: white;
    font-size: 12px;

}
.loginImg{
    margin-top: 14vh;
    height: 60vh;
}
.loginForm{
    margin-top: 200px;
    width: 35%;
}
.enterCodeForm{
    margin-top: 30vh;
    width:40%;
}
.countImg{
    height: 8vh;
}
.divCountImg{
    width: 35%;
    padding: 5vh;
}
.dropdown-menu{
    width: 100%;
    overflow: auto;
    max-height: 350px;
}
.progress-bar {
    background-color:  #44d551;
  }
  .progress{
    height: 4px;
  }
  .rectangleCode {
    font-size: 34px;
    width: 57px;
    height: 77px;
    border-radius: 5px;
    border: solid 1px rgba(255, 255, 255, 0.35);
    background-color: rgba(147, 152, 162);
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    appearance: none;
    margin: 0; 
  }
  /* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }