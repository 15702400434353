.secure-audio-play-img {
    border: none !important;
}
.secure-audio-audio {
    transform: scale(0.75);
}
.secure-audio-audio-popover {
    min-width: max-content;
    min-height: max-content;
}
#loading-bar-spinner.spinner {
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;
    position: absolute;
    z-index: 19 !important;
    animation: loading-bar-spinner 700ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border:  solid 4px transparent;
    border-top-color:  #2EC2FE !important;
    border-left-color: #2EC2FE !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.MuiButton-contained {
    color: none !important;
    box-shadow: none !important;
    background-color: unset !important
}
.MuiButton-root {
    color: none !important;
    padding: unset !important;
    font-size: unset !important;
    min-width: unset !important;
    box-sizing: unset !important;
    transition: none !important;
    font-family: none !important;
    font-weight: unset !important;
    line-height: unset !important;
    border-radius: unset !important;
    letter-spacing: unset !important;
    text-transform: unset !important;
}