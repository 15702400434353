.rtm-ff-poppins {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.rtm-input-label-header {
    font-size: 12px;
    color: #3c3f4a;
}
.rtm-silent-monitor-icon {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
}
.rtm-silent-monitor-inner-icon {
    position: absolute;
    top: 17px;
    left: 17px;
}
.rtm-table-main {
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
    background-color: #ffffff;
}
.ttm-table-header {
    display: flex;
    background-color: #fbfbfb;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
    min-height: 2.25rem;
    padding-left: 2.75rem;
    padding-top: 0.5rem;
}
.rtm-table-row {
    display: flex;
    /* height: 3.75rem; */
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
    padding-top: 1.25rem;
}
.rtm-table-username-cell {
    overflow-wrap: break-word;
    /* overflow: auto; */
}
.rtm-table-row-line {
    border-bottom: solid 1px #e3e5e7;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}
.rtm-silent-monitor-mouse-over {
    background-color: #ffffff;
    height: 2.9rem;
    width: 2.9rem;
    border-radius: 50%;
}
.rtm-silent-monitor-mouse-over:hover {
    background-color: rgba(237, 130, 130, 0.16);
}
.rtm-monitor-callback-input {
    font-size: 14px;
    color: #3c3f4a;
}
.rtm-error-in-sse-msg {
    margin-left: 40px;
    margin-top: auto;
    color: #f88658;
}
.rtm-error-in-sse-msg-h4 {
    display: contents;
}
.rtm-status-circle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px
}
.rtm-sse-status-main {
    display: flex;
    height: 2.375rem;
    margin-top: auto;
}
.rtm-change-display-icons-div {
    width: 118px;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}