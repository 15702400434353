.has-transcribe-poppins {
    font-family: Poppins;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.has-transcribe-show-language-header {
    font-size: 12px;
    color: rgba(60, 63, 74, 0.6);
    margin-top: 1.5rem;
}
.has-transcribe-link {
    font-size: 14px;
    color: #2ec2fe;
}
.has-transcribe-show-language {
    font-size: 14px;
    color: #3c3f4a;
    margin-top: 0.688rem;
}
.has-transcribe-in-progress {
    display: flex;
    font-size: 14px;
    color: #3c3f4a;
    justify-content: space-between;
}