body {
    font-family: 'Poppins', sans-serif !important;
}

.btn {
    border-radius: 5px;
}

.react-datepicker__input-container>input {
    width: 100%;
}