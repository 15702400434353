.user-message-cube-main {
    width: max-content;
    max-width: 70%;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px rgba(36, 49, 63, 0.12);
    background-color: #ffffff;
    padding: 1.25rem 1.25rem 1rem 1.25rem;
    margin-bottom: 1rem;

    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin: 15px 0;
    word-break: break-all;
    transform: rotate(180deg);
    direction: ltr;
}
.user-message-cube-number-and-time {
    display: flex;
    justify-content: space-between;
}
.user-message-cube-number {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
}
.user-message-cube-time {
    font-size: 12px;
    color: #8a8c92;
    margin-left: 1rem;
}
.user-message-cube-content {
    font-size: 14px;
    color: #3c3f4a;
    margin-top: 0.5rem;
}
.user-message-cube-not-delivered {
    margin-top: 1.25rem;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    color: #E94141;
    font-family: Poppins;
    display: flex;
    align-items: center;
    justify-content: right;
}
.user-message-cube-not-delivered-icon {
    margin-right: 0.375rem;
}
.user-message-cube-content-text {
    word-wrap: break-word;
}