.chart-screen-date-col {
    height: 82.5px;
}
.chart-screen-date-header {
    color: rgba(60,63,74,0.6);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}
.chart-screen-main {
    height: 100vh;
    overflow: hidden;
    width: 100%;
}
.chart-screen-charts-main {
    height: 80vh;
    overflow-x: auto;
    overflow-y: auto;
    box-sizing: border-box;
    border: 1px solid rgba(26,37,58,0.06);
    border-radius: 5px;
    background-color: #FBFBFB;
    padding-top: 29px;
    padding-left: 25px;
}
.chart-screen-dashboard-main {
    background-color: #F5F5F5;
    padding-left: 25px;
    padding-right: 25px;
}
.chart-screen-charts-line-1 {
    display: flex;
    flex-wrap: wrap;
}
.chart-screen-loader {
    position: absolute;
    width: 100px;
    height: auto;
    right: 45%;
    top: 30%;
    z-index: 100;
}
.chart-screen-boxes-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-right: auto;
}
.chart-screen-report-type {
    margin-right: 25px;
}
.chart-screen-to-date {
    margin-left: 25px;
}
.chart-screen-dashboard-header {
    color: #3C3F4A;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 33px;
    margin-bottom: 25px;
}
.chart-screen-arrow-right-icon {
    margin-top: 15px;
    margin-left: 25px;
}
.chart-screen-dropdown-formgroup {
    margin-left: 25px;
}
.chart-screen-submit-btn {
    color: #ffffff !important;
}
.chart-screen-submit-btn-form-group {
    margin-top: auto;
    margin-left: 60px;
}