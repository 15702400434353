body {
    font-family: 'Poppins', sans-serif !important;
}

.btn {
    border-radius: 5px;
}

.react-datepicker__input-container>input {
    width: 100%;
}
        .cursorPointer {
            cursor: pointer !important;
        }
        
        .btnBorder {
            border: 1px solid #2EC2FE;
        }
        
        .btnBorderButtom {
            border-bottom: 1px solid #2EC2FE;
        }
        
        .borderButtom {
            border-bottom: 1px solid #ECE7E7;
        }
        
        .btnColor1 {
            background-color: rgb(207, 200, 200);
        }
        
        .btnColor2 {
            background-color: #2EC2FE!important;
        }
        
        .btnColor3 {
            background-color: #44d551;
        }
        
        .btnColor4 {
            background-color: #e1e2e4;
        }
        
        .btnColor5 {
            background-color: #ebebeb;
        }
        
        .canceled {
            background-color: #f7cac93a;
        }
        
        .white {
            background-color: #ffffff;
        }
        
        .cursorDef {
            cursor: default !important;
        }
        
        .gray {
            color: rgba(60, 63, 74, 0.6) !important;
            font-family: Poppins, serif;
            font-weight: bold;
        }
        
        .border-5 {
            border-radius: 5px;
        }
        
        .Rectangle {
            border-radius: 5px;
            border: solid 1px rgba(255, 255, 255, 0.96);
            background-color: rgba(255, 255, 255, 0.05);
            text-align: center;
        }
        
        .dropItems {
            background-color: #ebebeb;
            border-radius: 5px;
        }
        
        .fundsModal>.modal-content {
            flex: 0 0 75%;
            max-width: 75%;
            font-family: 'Poppins', sans-serif !important;
            margin-top: 200px !important;
            padding: 0.563rem;
        }
        
        .customDatePickerWidth,
        .customDatePickerWidth>div.react-datepicker-wrapper,
        .customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
            width: 100%;
        }
        
        .widthModal>.modal-title {
            width: 100vw !important;
        }
        
        .paymentModal {
            max-width: 1400px;
        }
        
        .paymentContantModal>.modal-content {
            width: 1400px;
        }

        .loginGroupModal {
            max-width: 1000px;
        }
        
        .loginGroupModal>.modal-content {
            width: 1000px;
        }
        
        .iframeModal>.modal-content {
            width: 1002px;
        }
        
        .lableFont {
            color: rgba(60, 63, 74, 0.49);
        }
        
        .fontColor {
            color: #3C3F4A;
            font-family: 'Poppins', sans-serif !important;
        }
        
        .modal-contant {
            font-family: 'Poppins', sans-serif !important;
        }
        
        .no-border {
            border: none;
        }
        
        .Will-be-deleted-on-2 {
            /* height: 17px;
    margin: 1px 0 0 0px; */
            font-size: 12px;
            color: rgba(233, 65, 65, 0.6);
        }
        
        .UserSpikko {
            /* height: 20px;
            margin: 1px 0 0 5px; */
            font-size: 14px;
            font-weight: bold;
            margin-left: -30px;
            color: #3c3f4a;
        }
        
        .table td,
        .table tr {
            padding-left: 0.75rem;
            vertical-align: inherit;
            border-bottom: 1px solid #dee2e6;
        }
        
        .block {
            display: block;
        }
        
        .paginationItem {
            border: 50px !important;
        }
        
        .tableBorder {
            border: 1px solid #eeeeee;
            border-radius: 5px;
        }
        
        .poppins-regular {
            font-family: 'Poppins', sans-serif;
        }
        
        .pagination-new {
            font-family: 'Poppins', sans-serif;
            color: #3C3F4A;
            caret-color: #44d551;
        }
        
        .aa {
            position: inherit;
        }
        
        .no-border2 {
            max-width: 130px;
            border: none;
        }
        
        .border-52 {
            border-radius: 5px;
            min-width: 180px;
        }
        
        .isolate-the-design .custom-control-input:checked~.custom-control-label::before {
            border-color: #34b640 !important;
            background-color: #34b640 !important;
            border-radius: 2rem;
            width: 37px;
            height: 24px;
        }
        
        .isolate-the-design .custom-control-input~.custom-control-label::before {
            border-color: #6d7381 !important;
            background-color: #6d7381 !important;
            width: 37px;
            border-radius: 2rem;
            height: 24px;
        }
        
        .custom-switch .custom-control-label::after {
            width: 22px;
            height: 20px;
            background-color: #9199ab;
            border-radius: 2rem;
        }
        
        tbody::-webkit-scrollbar {
            width: 4px;
        }
        
        tbody::-webkit-scrollbar-track {
            /*box-shadow: inset 10 0 6px rgba(0, 0, 0, 0.3);*/
        }
        
        tbody::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid #e3e5e7;
        }

        img.hover-zoom:hover {
            -webkit-transform: scale(1.05);
                    transform: scale(1.05);
            cursor: pointer;
        }

        .mini-package-main {
            border-radius: 5px;
            border: solid 1px rgba(36, 49, 63, 0.12);
            background-color: #ffffff;
            padding-bottom: 0.875rem;
            padding-right: 0.2rem;
        }
        .choose-template-btn {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .report-btn {
            background-color: #ebebeb;
        }
        .report-user-btn-line {
            border-left: 1px solid #FFFFFF;;
        }
        .report-user-sms-report-btn {
            padding-top: 5px;
        }
        .report-user-call-report-btn {
            padding-top: 3px;
        }
        .iphon-icon-messages-report-btn {
            margin-left: 2px;
        }
        .row-table-messages-report-iphone-icon {
            margin-left: 6px;
        }
        .row-table-reset-pass-modal {
            margin-top: 8%;
            max-width: 370px;
        }
        #dashboard_more_option_call_report {
            margin-left: 0.131rem;
        }
        #dashboard_more_option_show_payments {
            padding-left: 1.9rem;
        }
        #dashboard_more_option_open_user_page {
            margin-top: 0.6rem; 
            padding-left: 1.7rem;
        }
        #dashboard_more_option_edit_caller_ids {
            margin-left: 0.131rem;
        }
        #sms-report-icon {
            margin-left: 0.313rem;
        }
        #dashboard_btn_users_sms_report {
            margin-top: 0.125rem;
        }

.siteBar {
    width: 7vw;
    background-color: #1A253A;
    min-height: 100vh;

}
.imgStyle {
    max-width: -webkit-min-content !important;
    max-width: min-content !important;
}

.navBar{
    height: 80px ;
    background-color: #FBFBFB;
}
.dropdown-menu {min-width: -webkit-min-content;min-width: min-content; }
#advanced_features_dropdown_di_group_ringing {
    height: 3.125rem;
    margin-left: 0.6rem;
}
#advanced_features_dropdown_di_sequential_ringing {
    height: 3.125rem;
    margin-left: 0.6rem;
}
  .line-1 {
      width: 100%;
      background-color:#ece7e7;
      height:1px;
    }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }

  .v-center {
    display: flex;
    align-items: center;
  }

  .progress{
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color:#e3e5e7 ;
  }
  .progress > div{
    height: 4px;
    border-radius: 4px;
    background-color:#2ec2fe ;
  }

  .fc-grey{
    color: rgba(60, 63, 74, 0.6)
  }

  .fs-10 {
    font-size: 10px;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-22 {
    font-size: 22px;
    color: #3c3f4a;
  }

  .center {
    vertical-align: middle!important;
  }

  .border-grey{
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
  }

  .box-yellow{
    border-radius: 5px;
    border: solid 1px #fffcea;
    padding-left: 25px;
  }

  .background-lightGrey{
    background-color: #fbfbfb;
    border-radius: 5px;
    padding: 15px;
  }

  .background-lightGrey-2 {
    background-color: #fbfbfb;
    border-radius: 5px;
    padding-left: 15px;
  }

  .light-blue{
    color:#2EC2FE
  }

  .light-red{
    color:#f89999
  }

  .pointer {
    cursor: pointer;
  }
  table {
    /*width: 100%;*/
  }

   .thead, .tbody, .tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .tr{
    /* height: 30px; */
    border: solid 1px rgba(26, 37, 58, 0.06);
    background-color: #fbfbfb;
    margin-bottom: 8px;
    border-radius:10px ;
  }
  .tr-disabled{
    background-color: rgb(243, 243, 243);
  }
  .tr-disabled > .h{
    color: rgb(160, 160, 160);
  }

  .tr-disabled > .disabled-row{
    opacity: 0.4;
  }

  .tr-enabled {
    background-color: #fbfbfb;
  }
  .tbody {
    display: block;
    overflow: auto;
    table-layout: fixed;

    border-collapse: separate;
    border-spacing: 0 ;
    /*max-height: 250px;*/
  }

  .oneLine{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }


  .ss{
    
    margin-right: 30px;
    padding-right: 80px;
  }


  .arrowDown:after{
    content: '';
    border: 4px solid transparent;
    border-top: 4px solid white;
    margin-left: 2px;
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: bottom;

  }
  .af-modal-header {
    font-family: Poppins;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
  }
  .af-modal-header-brackets {
    font-size: 18px;
    color: rgba(60, 63, 74, 0.6);
  }
  .country-select {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
  }
  .country-select-selected {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
  }
  .th-3-points {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .aaBody > tr { 
    border-radius: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
  }
  
  .aaBody > tr > td { 
    padding: 0;
    margin: 0;
  }

  .table .gr-mb > tr > th {
    padding-left: 0;
  }

  .sr-form {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .sr-form-label {
    color: #3c3f4a;
  }
  /* SequentialRinging */
   #sr-th-1 {
    width: 24%;
   }
   #sr-th-2 {
    width: 20%;
  }
  #sr-th-3 {
    width: 20%;
  }
  #sr-th-4 {
    width: 17%;
  }
  #sr-td-1 {
    width: 24%;
   }
   #sr-td-2 {
    width: 20%;
  }
  #sr-td-3 {
    width: 20%;
  }
  #sr-td-4 {
    width: 17%;
  }
  /* TimeForward */
  #tf-th-1 {
    width: 14%;
   }
   #tf-th-2 {
    width: 15%;
  }
  #tf-th-3 {
    width: 23%;
  }
  #tf-td-1 {
    width: 14%;
   }
   #tf-td-2 {
    width: 15%;
  }
  #tf-td-3 {
    width: 23%;
  }
  #tf-tr{
    padding-left: 0;
  }
  /* AutoAttendant */
  #aa-th-1 {
    width: 18%;
   }
   #aa-th-2 {
    width: 27%;
  }
  #aa-th-3 {
    width: 20%;
  }
  #aa-td-1 {
    width: 18%;
   }
   #aa-td-2 {
    width: 27%;
  }
  #aa-td-3 {
    width: 20%;
  }
/* .flag-icon {
    width: 18px;
    height: 18px;
} */

@font-face {
    font-family: 'BabelStoneFlags';
    src: local('BabelStoneFlags'), url(/static/media/BabelStoneFlags.22909087.ttf) format('truetype');
}
.ac-main {
    font-family: 'BabelStoneFlags';
}
.mini-package-header {
    display: flex;
    padding-bottom: 0.5rem;
    margin-bottom: 1.25rem;
}
.mini-package-header-title {
    margin-top: 11px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
}
.mini-package-header-icon-local-num-title {
    margin-left:7%;
}
.mini-package-details-list {
    display: flex;
    height: 3rem;
    padding-top: 1rem;
}
.mini-package-details-list-font {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
}
.mini-package-list-bottom-line {
    border: solid 1px #e3e5e7;
    margin: 0.413rem 1.25rem 0.475rem;
}
.mini-package-edit-forward-to-icon{
    margin-left: 0.625rem;
    height: 1.2rem;
}
img.mini-package-edit-forward-to-icon:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    cursor: pointer;
}
.mini-package-renew-now {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2ec2fe;
}
.mini-package-3-points-icon {
    height: 0.3rem;
}
img.mini-package-3-points-icon:hover {
    -webkit-transform: scale(1.10);
            transform: scale(1.10);
    cursor: pointer;
}
.mini-package-usd {
    font-size: 14px;
    font-weight: 600;
    color: #3c3f4a;
}
img.mini-package-forward-to:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    cursor: pointer;
}
.advanced-features-modal-btn-list {
    border-radius: 5px;
}
.advanced-features-modal-btn-list:hover {
    cursor: pointer;
    background-color: #f1f1f5;
}
.advanced-features-modal-header {
    font-family: Poppins;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
}
img.mini-package-expiry-date-not-set-icon:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    cursor: pointer;
}
#advanced_features_dropdown_di_group_ringing {
    height: 2.625rem;
    margin-left: 0.6rem;
    margin-top: 0.4rem;
}
#advanced_features_dropdown_di_sequential_ringing {
    height: 2.625rem;
    margin-left: 0.6rem;
}
.sms-report-modal-header {
    text-align: center;
    margin-top: 1rem;
}
.sms-reprt-date-header-1 {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
}
.sms-reprt-date-header-2 {
    font-size: 14px;
    color: #3c3f4a;
}
.sms-report-date-header {
    font-size: 12px;
    color: #3c3f4a;
}
.sms-report-date-col {
    margin-top: 1rem;
    justify-content: space-between;
}
.sms-report-all-users-main {
    width: 24rem;
    height: 21.188rem;
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
    background-color: #ffffff;
    margin-top: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.188rem;
    padding-top: 1rem;
}
.sms-report-buttons-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1.938rem;
}
.sms-report-export-icon {
    margin-right: 0.563rem;
    margin-bottom: 0.3rem;
}
.sms-report-export-as-table-button {
    padding: 0.563rem 2.438rem 0.563rem 2.5rem;
}
.sms-report-download-as-csv-button {
    padding: 0.563rem 1.188rem;
}
.sms-report-user-list {
    height: 2.688rem;
    display: flex;
    flex-direction: column;
    padding-top: 0.688rem;
    padding-left: 0.2rem;
}
.sms-report-user-list-bottom-line {
    border-bottom: solid 0.5px #ece7e7;
    margin-top: auto;
    margin-right: 1.125rem;
}
.sms-report-user-list .custom-control-input:checked~.custom-control-label::before {
    border-color: #ece7e7 !important;
    background-color: #2ec2fe !important;
}
.sms-report-user-list .custom-control-input~.custom-control-label::before {
    border-color: #ece7e7 !important;
    background-color: #ffffff !important;
}
.sms-report-all-users-buttons-main {
    display: flex;
    justify-content: space-between;
}
.sms-report-all-users-button {
    border: none;
    background-color: #ffffff;
    font-family: Poppins;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
}
.sms-report-all-users-list {
    height: 13.875rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1.75rem;
}
.sms-report-all-users-list::-webkit-scrollbar {
    width: 0.375em;
}
.sms-report-all-users-list::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.sms-report-all-users-list::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
.react-datepicker-popper {
    z-index: 1000;
}
.sms-report-search-input {
    margin: 4px 20px 0 20px;
}
.sms-report-modal-header {
    text-align: center;
    margin-top: 1rem;
}
.sms-reprt-date-header-1 {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
}
.sms-reprt-date-header-2 {
    font-size: 14px;
    color: #3c3f4a;
}
.sms-report-date-header {
    font-size: 12px;
    color: #3c3f4a;
}
.sms-report-date-col {
    margin-top: 1rem;
    justify-content: space-between;
}
.sms-report-buttons-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1.938rem;
}
.sms-report-export-icon {
    margin-right: 0.563rem;
    margin-bottom: 0.3rem;
}
.sms-report-export-as-table-button {
    padding: 0.563rem 2.438rem 0.563rem 2.5rem;
}
.sms-report-download-as-csv-button {
    padding: 0.563rem 1.188rem;
}
.sms-report-user-list {
    height: 2.688rem;
    display: flex;
    flex-direction: column;
    padding-top: 0.688rem;
    padding-left: 0.2rem;
}
.sms-report-user-list-bottom-line {
    border-bottom: solid 0.5px #ece7e7;
    margin-top: auto;
    margin-right: 1.125rem;
}
.sms-report-user-list .custom-control-input:checked~.custom-control-label::before {
    border-color: #ece7e7 !important;
    background-color: #2ec2fe !important;
}
.sms-report-user-list .custom-control-input~.custom-control-label::before {
    border-color: #ece7e7 !important;
    background-color: #ffffff !important;
}
.sms-report-all-users-buttons-main {
    display: flex;
    justify-content: space-between;
}
.sms-report-all-users-button {
    border: none;
    background-color: #ffffff;
    font-family: Poppins;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
}
.sms-report-all-users-list {
    height: 13.875rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1.75rem;
}
.sms-report-all-users-list::-webkit-scrollbar {
    width: 0.375em;
}
.sms-report-all-users-list::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.sms-report-all-users-list::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
.react-datepicker-popper {
    z-index: 1000;
}
.select-users-modal-header {
    text-align: center;
    margin-top: 1rem;
}
.sms-reprt-date-header-1 {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
}
.sms-reprt-date-header-2 {
    font-size: 14px;
    color: #3c3f4a;
}
.select-users-date-header {
    font-size: 12px;
    color: #3c3f4a;
}
.select-users-date-col {
    margin-top: 1rem;
    justify-content: space-between;
}
.select-users-all-users-main {
    width: 24rem;
    height: 21.188rem;
    border-radius: 5px;
    background-color: #ffffff;
    padding-left: 1.25rem;
    padding-right: 1.188rem;
    padding-top: 1rem;
}
.select-users-buttons-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1.938rem;
}
.select-users-export-icon {
    margin-right: 0.563rem;
    margin-bottom: 0.3rem;
}
.select-users-export-as-table-button {
    padding: 0.563rem 2.438rem 0.563rem 2.5rem;
}
.select-users-download-as-csv-button {
    padding: 0.563rem 1.188rem;
}
.select-users-user-list {
    height: 2.688rem;
    display: flex;
    flex-direction: column;
    padding-top: 0.688rem;
    padding-left: 0.2rem;
}
.select-users-user-list-bottom-line {
    border-bottom: solid 0.5px #ece7e7;
    margin-top: auto;
    margin-right: 1.125rem;
}
.select-users-user-list .custom-control-input:checked~.custom-control-label::before {
    border-color: #ece7e7 !important;
    background-color: #2ec2fe !important;
}
.select-users-user-list .custom-control-input~.custom-control-label::before {
    border-color: #ece7e7 !important;
    background-color: #ffffff !important;
}
.select-users-all-users-buttons-main {
    display: flex;
    justify-content: space-between;
}
.select-users-all-users-button {
    border: none;
    background-color: #ffffff;
    font-family: Poppins;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
}
.select-users-all-users-list {
    height: 13.875rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1.75rem;
}
.select-users-all-users-list::-webkit-scrollbar {
    width: 0.375em;
}
.select-users-all-users-list::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.select-users-all-users-list::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
.react-datepicker-popper {
    z-index: 1000;
}
.select-users-search-input {
    margin: 4px 20px 0 20px;
}
.reset-pass-modal-header {
    justify-content: space-around;
}
.reset-pass-submit-btn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    border: none;
}
.reset-pass-modal-header-username {
    font-weight: bold;
}
img.transcription-icon:hover {
    -webkit-transform: scale(1.10);
            transform: scale(1.10);
    cursor: pointer;
}
.min-sec-formgroup-main {
    margin-bottom: 10px;
}
.table-row-number {
    cursor: pointer;
}
.has-transcribe-poppins {
    font-family: Poppins;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.has-transcribe-show-language-header {
    font-size: 12px;
    color: rgba(60, 63, 74, 0.6);
    margin-top: 1.5rem;
}
.has-transcribe-link {
    font-size: 14px;
    color: #2ec2fe;
}
.has-transcribe-show-language {
    font-size: 14px;
    color: #3c3f4a;
    margin-top: 0.688rem;
}
.has-transcribe-in-progress {
    display: flex;
    font-size: 14px;
    color: #3c3f4a;
    justify-content: space-between;
}
.new-contact-number-col {
    display: flex;
    align-items: center;
}
.secure-audio-play-img {
    border: none !important;
}
.secure-audio-audio {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
}
.secure-audio-audio-popover {
    min-width: -webkit-max-content;
    min-width: max-content;
    min-height: -webkit-max-content;
    min-height: max-content;
}
#loading-bar-spinner.spinner {
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;
    position: absolute;
    z-index: 19 !important;
    -webkit-animation: loading-bar-spinner 700ms linear infinite;
            animation: loading-bar-spinner 700ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border:  solid 4px transparent;
    border-top-color:  #2EC2FE !important;
    border-left-color: #2EC2FE !important;
    border-radius: 50%;
}

@-webkit-keyframes loading-bar-spinner {
  0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes loading-bar-spinner {
  0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.MuiButton-contained {
    color: none !important;
    box-shadow: none !important;
    background-color: unset !important
}
.MuiButton-root {
    color: none !important;
    padding: unset !important;
    font-size: unset !important;
    min-width: unset !important;
    box-sizing: unset !important;
    transition: none !important;
    font-family: none !important;
    font-weight: unset !important;
    line-height: unset !important;
    border-radius: unset !important;
    letter-spacing: unset !important;
    text-transform: unset !important;
}
.report-popover {
    display: flex;
}
.report-person-add-icon {
    margin-left: 6px;
}
.report-person-add-icon:hover {
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.user-terms-modal-header {
    text-align: center;
}
.user-terms-modal-body-link {
    text-decoration: underline;
    font-size: 16x;
    color: #2ec2fe;
}
.wrapper{
    background-color: #1A253A;
   min-height: 100vh;
    color: white;
    font-size: 12px;

}
.loginImg{
    margin-top: 14vh;
    height: 60vh;
}
.loginForm{
    margin-top: 200px;
    width: 35%;
}
.enterCodeForm{
    margin-top: 30vh;
    width:40%;
}
.countImg{
    height: 8vh;
}
.divCountImg{
    width: 35%;
    padding: 5vh;
}
.dropdown-menu{
    width: 100%;
    overflow: auto;
    max-height: 350px;
}
.progress-bar {
    background-color:  #44d551;
  }
  .progress{
    height: 4px;
  }
  .rectangleCode {
    font-size: 34px;
    width: 57px;
    height: 77px;
    border-radius: 5px;
    border: solid 1px rgba(255, 255, 255, 0.35);
    background-color: rgba(147, 152, 162);
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    appearance: none;
    margin: 0; 
  }
  /* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
.two-factor-main {
    background-color: #1A253A;
    min-height: 100vh;
    color: white;
    font-size: 12px;
    padding-top: 10%;
}
.two-factor-content-div {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}
.two-factor-code-input-header {
    margin-bottom: 30px;
}
.two-factor-verify-btn {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 30%;
}

.Rectangle2 {
    border-radius: 5px;
    border: solid 1px rgba(36,49,63,0.12);
    text-align: center;
  }
.cardBorder {
    border-radius: 5px;
    border: solid 1px rgba(26,37,58,0.06);
    padding: 10px;
  }
  .mfa-modal-main {
    margin-top: 8%;
    max-width: 382px;
  }
.mfa-modal-qr-image {
    width: 350px;
}
.mfa-modal-header {
    justify-content: space-around;
}
.mfa-modal-header-div-main {
    font-weight: bold;
}
.mfa-modal-body-div-main {
    display: flex;
    flex-direction: column;
}
.mfa-modal-verify-btn {
    margin-top: 10px;
    background-color: #87CEFA;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mfa-modal-body-spinner {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}
.mfa-modal-code-input-header {
    text-align: center;
    font-weight: bold;
}
.mfa-modal-explanation-input-code {
    margin-top: 10px;
    text-align: center;
}
.mfa-modal-Manual {
    word-wrap: break-word;
}
.mfa-modal-qr-Manual-explanation {
    font-weight: bold;
    text-align: center;
}
    .contactUsBody {
        height: 90vh;
        width: 100%;
        background-color: #1a253a;
        font-size: 12px;
    }
    
    .form {
        width: 60%;
        color: white;
    }
    
    .submit {
        width: 100%;
        background-color: #2ec2fe;
        font-size: 14px;
        font-weight: bold
    }
    
    .red {
        color: red;
    }
    
    /*.white {*/
    /*    color: white*/
    /*}*/

    .modalBody{
        position: fixed;
        height: 100vh;
        width: 75%;
    }
.cell-content {
    height: 77px;
    padding: 18px 25px 17px 15px;
    margin: 26px 15px 0 26px;
    border-radius: 5px;
    border: solid 1px rgba(26, 37, 58, 0.06);
    background-color: #fbfbfb;
    font-size: 14px;
}

.i-name {
    font-weight: bold;
}

.i-button {
    width: 250px;
}

.crm-modal {
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    color: black;
    font-size: 12px;
}

.crm-save {
    margin-top: 43px;
    border: none;
    width: 100%;
}

.img>img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}
.crm-integration-cell-login-or-update-btn {
    margin-left: 0.938rem;
}
.crm-integration-cell-personal-user-settings-btn {
    font-size: 14px;
}
.personal-user-settings-modal-header {
    display: flex;
}
.personal-user-settings-modal-logo {
    width: 9.25rem;
    height: 3.063rem;
}
.personal-user-settings-modal-header-text {
    font-size: 22px;
    color: #3c3f4a;
    margin-left: 1rem;
    -webkit-transform: translateY(25%);
            transform: translateY(25%)
}
.personal-user-settings-modal-details {
    display: flex;
    height: 3.125rem;
    border-radius: 5px;
    border: solid 1px rgba(26, 37, 58, 0.06);
    background-color: #fbfbfb;
    padding-left: 0.938rem;
    margin-bottom: 0.5rem;
    /* padding: 0.5rem 0.938rem 0.563rem 0.938rem; */
}
.personal-user-settings-modal-details-input {
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
    outline: none;
    height: 2.313rem;
    max-width: 11.125rem;
    padding-left: 0.5rem;
    font-size: 14px;
    color: rgba(60, 63, 74, 0.6);
    margin-top: 0.4rem;
    margin-left: 0.3rem;
}
.personal-user-settings-modal-details-email {
    font-size: 14px;
    color: #3c3f4a;
    width: 36%;
    margin-top: 0.8rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0.2rem;
}
.personal-user-settings-modal-details-name {
    font-size: 14px;
    color: #3c3f4a;
    width: 36%;
    margin-top: 0.8rem;
}
.personal-user-settings-modal-details-headers {
    display: flex;
    font-size: 12px;
    color: rgba(60, 63, 74, 0.6);
    padding-left: 0.938rem;
    margin-bottom: 0.5rem;
}
.personal-user-settings-modal-details-header-email {
    width: 36%;
}
.personal-user-settings-modal-details-header-full-name {
    width: 36%;
}
.personal-user-settings-modal-details-main {
    max-height: 10.5rem;
    overflow-y: auto;
    position: relative;
}
.personal-user-settings-modal-details-main::-webkit-scrollbar {
    width: 0.25em;
}
.personal-user-settings-modal-details-main::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
}
.personal-user-settings-modal-details-main::-webkit-scrollbar-thumb {
    background-color: #e3e5e7;
    border-radius: 5px;
}

.personal-user-settings-loader {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.personal-user-settings-floating-loader {
    position: absolute;
    width: 77px;
    top: 1px;
    left: 50%;
}
.personal-user-settings-modal-details-inputs {
    display: flex;
}
.personal-user-settings-modal-details-dynamic-header {
    width: 28%;
}
.personal-user-settings-modal-details-dynamic-active-header {
    width: 16%;
}
.personal-user-settings-modal-details-checkbox {
    margin-top: auto;
    margin-bottom: auto;
    width: 6rem;
}
.personal-user-settings-modal-details-active-checkbox {
    text-align: center;
}
.rtm-ff-poppins {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.rtm-input-label-header {
    font-size: 12px;
    color: #3c3f4a;
}
.rtm-silent-monitor-icon {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
}
.rtm-silent-monitor-inner-icon {
    position: absolute;
    top: 17px;
    left: 17px;
}
.rtm-table-main {
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
    background-color: #ffffff;
}
.ttm-table-header {
    display: flex;
    background-color: #fbfbfb;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
    min-height: 2.25rem;
    padding-left: 2.75rem;
    padding-top: 0.5rem;
}
.rtm-table-row {
    display: flex;
    /* height: 3.75rem; */
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
    padding-top: 1.25rem;
}
.rtm-table-username-cell {
    overflow-wrap: break-word;
    /* overflow: auto; */
}
.rtm-table-row-line {
    border-bottom: solid 1px #e3e5e7;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}
.rtm-silent-monitor-mouse-over {
    background-color: #ffffff;
    height: 2.9rem;
    width: 2.9rem;
    border-radius: 50%;
}
.rtm-silent-monitor-mouse-over:hover {
    background-color: rgba(237, 130, 130, 0.16);
}
.rtm-monitor-callback-input {
    font-size: 14px;
    color: #3c3f4a;
}
.rtm-error-in-sse-msg {
    margin-left: 40px;
    margin-top: auto;
    color: #f88658;
}
.rtm-error-in-sse-msg-h4 {
    display: contents;
}
.rtm-status-circle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px
}
.rtm-sse-status-main {
    display: flex;
    height: 2.375rem;
    margin-top: auto;
}
.rtm-change-display-icons-div {
    width: 118px;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.st-table-main {
    border-radius: 5px;
    border: solid 1px rgba(36, 49, 63, 0.12);
    background-color: #ffffff;
}
.st-table-row {
    display: flex;
    /* height: 3.75rem; */
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3f4a;
    padding-top: 1.25rem;
}
.st-table-username-cell {
    overflow-wrap: break-word;
}
.st-table-header {
    display: flex;
    background-color: #fbfbfb;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(60, 63, 74, 0.6);
    min-height: 2.25rem;
    padding-left: 2.75rem;
    padding-top: 0.5rem;
}
.dice-dislay-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.ddb-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    /* height: 93px; */
    width: 320px;
    border: 1px solid rgba(36,49,63,0.12);
    border-radius: 5px;
    background-color: #FFFFFF;
    margin: 10px;
    padding: 15px;
    font-size: 14px;
    font-weight: bold;
    color:  rgba(60,63,74,0.6);;
}
.ddb-call-active-text {
    opacity: 0;
    transition: opacity 2s;
}
.ddb-did-num {
    margin-top: 4px;
    margin-bottom: 4px;
}
.ddb-marg-text-from-icon {
    margin-left: 10px;
}
.rtm-change-display-icon-zoom-over:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    cursor: pointer;
}
.users-sms-report-main-div {
    margin-left: 1.563rem;
    margin-right: 1.563rem;
    margin-top: 1.125rem;
}
.users-sms-report-above-main-div {
    display: flex;
}
.users-sms-report-above-main-text {
    font-size: 22px;
    color: #3c3f4a;
}
.users-sms-report-download-as-csv-button {
    min-width: 11.625rem;
    height: 2.375rem;
    margin-top: auto;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
}
.users-sms-report-back-btn {
    min-width: 6rem;
    height: 2.375rem;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}
.users-sms-report-arrow-left-icon {
    margin-right: 6px;
}
.users-sms-report-dashboard-main {
    border-radius: 5px;
    border: solid 1px rgba(26, 37, 58, 0.06);
}
.users-sms-report-main-headers {
    display: flex;
    padding-top: 1.25rem;
    padding-bottom: 1.313rem;
}
.users-sms-report-main-header-bold {
    font-size: 18px;
    font-weight: bold;
    color: #3c3f4a;
    display: flex;
    width: 65%;
}
.users-sms-report-main-header-number {
    font-size: 14px;
    color: #3c3f4a;
    display: flex;
    width: 35%;
    padding-top: 0.313rem;
}
.users-report-dashboard-details {
    display: flex;
    min-height: 43rem;
    max-height: 43rem;
}
/* Left column  */
.users-report-dashboard-users-list {
    border: solid 1px rgba(26, 37, 58, 0.06);
    width: 30%;
    overflow-y: auto;
}
.users-report-dashboard-users-list::-webkit-scrollbar {
    width: 0.375em;
}
.users-report-dashboard-users-list::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.users-report-dashboard-users-list::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
/* Middle column */
.users-report-dashboard-users-number {
    border: solid 1px rgba(26, 37, 58, 0.06);
    background-color:  #f5f5f5;
    width: 30%;
    overflow-y: auto;
}
.users-report-dashboard-users-number::-webkit-scrollbar {
    width: 0.375em;
}
.users-report-dashboard-users-number::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.users-report-dashboard-users-number::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
/* Right column */
.users-report-dashboard-users-messages {
    border: solid 1px rgba(26, 37, 58, 0.06);
    width: 40%;
    padding: 1.25rem;
    background-color: #e9e9e9;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
}
.users-report-dashboard-users-messages::-webkit-scrollbar {
    width: 0.375em;
}
.users-report-dashboard-users-messages::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.users-report-dashboard-users-messages::-webkit-scrollbar-thumb {
    background-color: #9199ab;
    border-radius: 5px;
}
.users-sms-report-main-header-users-number {
    margin-left: 1.813rem;
    width: 46%;
}
.users-sms-report-main-header-icon{
    width: 9%;
}
.users-sms-report-main-header-conversations-number {
    width: 36%;
}
.users-sms-report-main-header-from-number {
    margin-left: 2rem;
    width: 40%;
}
.users-sms-report-main-header-to-number {
    cursor: pointer;
}
.users-sms-report-main-header-to-icon {
    width: 6%;
}
.user-sms-report-no-message {
    display: flex;
}
.user-sms-report-no-message-text {
    font-size: 18px;
    color: #3c3f4a;
    margin-left: auto;
    margin-right: auto;
}
#user-sms-report-next-to-users-messages {
    float: left;
    clear: both;
}
.users-sms-report-download-as-csv-spinner {
    margin-left: 0.4rem;
}
.usr-chat {
    width: 100%;
    max-width: 800px;
    height: calc(100vh - 50px);
    min-height: 100%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    direction: rtl;
}
.users-sms-report-above-main-date-inputs {
    display: flex;
    margin-left: auto;
}
.users-report-arrow-icon:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    cursor: pointer;
}
.users-sms-report-popover {
    display: flex;
}
.users-sms-report-person-add-icon {
    margin-left: 20px;
    height: 20px;
}
.users-sms-report-person-add-icon:hover {
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.sms-report-user-details-main {
    border:  solid 1px #ece7e7;
    display: flex;
    cursor: pointer;
}
.sms-report-user-details-user-icon-div {
    position: relative;
    margin-left: 1.188rem;
    margin-top: 7%;
    margin-bottom: 7%;
}
.sms-report-user-details-user-icon {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #44d551;
    top: 1.938rem;
    left: 1.75rem;
}
.sms-report-user-details-user-name {
    font-size: 14px;
    color: #3c3f4a;
}
.sms-report-user-details-date {
    font-size: 12px;
    color: #8a8c92;
    margin-top: 0.188rem;
}
.sms-report-user-details-around-date-and-name {
    display: flex;
    margin-top: 6%;
    margin-left: 3%;
    margin-right: 5%;
    justify-content: space-between;
}
.sms-report-user-details-right {
    width: 100%;
}
.sms-report-user-details-user-email {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
    margin-left: 3%;
    margin-top: 4%;
}
.user-conversations-main {
    width: 100%;
    height: 2.375rem;
    display: flex;
    border: solid 1px #e9e9e9;
    cursor: pointer;
    padding-top: 2%;
}
.user-conversations-icon {
    margin-left: 1.375rem;
}
.user-conversations-number {
    margin-left: 0.785rem;
    font-size: 14px;
    color: #3c3f4a;
}
.user-conversations-select-icon {
    margin-bottom: 0.4rem;
}
.user-conversations-details-main {
    border: solid 1px #ece7e7;
    background-color: #ffffff;
    cursor: pointer;
}
.user-conversations-details-from {
    display: flex;
    margin-top: 4%;
    margin-left: 7%;
}
.user-conversations-details-from-number-main {
    font-size: 14px;
    color: #3c3f4a;
    margin-left: 2%;
    display: flex;
    justify-content: space-between;
    width: 85%;
}
.user-conversations-details-from-number {
    font-weight: bold;
}
.user-conversations-details-to {
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-top: 3%;
    margin-left: 8%;
    margin-bottom: 3%;
}
.user-conversations-details-to-number {
    font-size: 14px;
    color: #3c3f4a;
    margin-left: 0.5rem;
}
.user-conversations-details-to-latest-time {
    font-size: 14px;
    color: #3c3f4a;
    margin-right: 4%;
}
.user-conversations-user-name-icon {
    height: 1.625rem;
    margin-left: 0.633rem;
}
.user-conversations-details-to-left-side {
    display: flex;
    align-items: center;
}
.other-message-cube-main {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #2ec2fe;
    background-color: #f5fcff;
    width: -webkit-max-content;
    width: max-content;
    max-width: 70%; 
    padding: 1.25rem 1.25rem 1rem 1.25rem;
    margin-right: auto;
    margin-bottom: 1rem;

    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    direction: ltr;
}
.other-message-cube-name-and-time {
    display: flex;
    justify-content: space-between;
}
.other-message-cube-name {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
}
.other-message-cube-time {
    font-size: 12px;
    color: #8a8c92;
    margin-left: 1rem;
}
.other-message-cube-content {
    font-size: 14px;
    color: #3c3f4a;
    margin-top: 0.5rem;
}
.other-message-cube-content-text {
    word-wrap: break-word;
}
.message-link {
    color: #72a3d8;
}
.file-msg-main {
    display: flex;
    box-sizing: border-box;
    border: 1px solid #E9E9E9;
    border-radius: 6px;
    background-color: #FFFFFF;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    padding-left: 0.625rem;
}
.file-msg-main-hover:hover{
    cursor: pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.file-msg-details-main {
    margin-left: 0.563rem;
}
.file-msg-file-name {
    color: #3C3F4A;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 0.563rem;
}
.file-msg-file-details {
    color: #8A8C92;
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 0.5rem;
}
.file-msg-pdf-icon {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
.file-msg-docs-icon {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
.file-msg-image-icon {
    margin-top: 0.813rem;
    margin-bottom: 0.813rem;
}
.file-msg-modal-media {
    width: -webkit-max-content;
    width: max-content;
    max-width: -webkit-max-content;
    max-width: max-content;
}
.file-msg-image {
    max-width: 750px;
}
.file-msg-video {
    max-width: 750px;
}
.file-ms-x-icon {
    margin-left: auto;
    margin-right: 15px;
}
.user-message-cube-main {
    width: -webkit-max-content;
    width: max-content;
    max-width: 70%;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px rgba(36, 49, 63, 0.12);
    background-color: #ffffff;
    padding: 1.25rem 1.25rem 1rem 1.25rem;
    margin-bottom: 1rem;

    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin: 15px 0;
    word-break: break-all;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    direction: ltr;
}
.user-message-cube-number-and-time {
    display: flex;
    justify-content: space-between;
}
.user-message-cube-number {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
}
.user-message-cube-time {
    font-size: 12px;
    color: #8a8c92;
    margin-left: 1rem;
}
.user-message-cube-content {
    font-size: 14px;
    color: #3c3f4a;
    margin-top: 0.5rem;
}
.user-message-cube-not-delivered {
    margin-top: 1.25rem;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    color: #E94141;
    font-family: Poppins;
    display: flex;
    align-items: center;
    justify-content: right;
}
.user-message-cube-not-delivered-icon {
    margin-right: 0.375rem;
}
.user-message-cube-content-text {
    word-wrap: break-word;
}
.group-msg-header-main {
    display: flex;
    justify-content: space-between;
    width: 35%;
}
.group-msg-header-group-name {
    color: #3C3F4A;
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27px;
}
.group-msg-header-group-numbers {
    color: #3C3F4A;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    margin-right: 2.25rem;
}
.group-msg-header-group-name-main {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
}
.group-msg-header-info-icon {
    margin-left: 2.25rem;
    margin-right: 1.313rem;
    cursor: pointer;
}
.group-msg-header-arrow-right-small {
    
}
.group-msg-header-group-name-icon {
    margin-left: 0.633rem;
}
.group-msg-header-pophover-group-name {
    color: #3C3F4A;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 0.5rem;
}
.group-msg-header-all-num-divider {
    border-bottom: 1px solid #ECE7E7;
    box-sizing: border-box;
    margin: 3px 15px 3px 0;
}
.chart-screen-date-col {
    height: 82.5px;
}
.chart-screen-date-header {
    color: rgba(60,63,74,0.6);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}
.chart-screen-main {
    height: 100vh;
    overflow: hidden;
    width: 100%;
}
.chart-screen-charts-main {
    height: 80vh;
    overflow-x: auto;
    overflow-y: auto;
    box-sizing: border-box;
    border: 1px solid rgba(26,37,58,0.06);
    border-radius: 5px;
    background-color: #FBFBFB;
    padding-top: 29px;
    padding-left: 25px;
}
.chart-screen-dashboard-main {
    background-color: #F5F5F5;
    padding-left: 25px;
    padding-right: 25px;
}
.chart-screen-charts-line-1 {
    display: flex;
    flex-wrap: wrap;
}
.chart-screen-loader {
    position: absolute;
    width: 100px;
    height: auto;
    right: 45%;
    top: 30%;
    z-index: 100;
}
.chart-screen-boxes-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-right: auto;
}
.chart-screen-report-type {
    margin-right: 25px;
}
.chart-screen-to-date {
    margin-left: 25px;
}
.chart-screen-dashboard-header {
    color: #3C3F4A;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 33px;
    margin-bottom: 25px;
}
.chart-screen-arrow-right-icon {
    margin-top: 15px;
    margin-left: 25px;
}
.chart-screen-dropdown-formgroup {
    margin-left: 25px;
}
.chart-screen-submit-btn {
    color: #ffffff !important;
}
.chart-screen-submit-btn-form-group {
    margin-top: auto;
    margin-left: 60px;
}
.data-cell-main {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 138px;
    width: 172px;
    border: 1px solid rgba(36,49,63,0.12);
    border-radius: 5px;
    background-color: #FFFFFF;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 25px;
    margin-bottom: 25px;
}
.data-cell-header {
    height: 40px;
    color: #3C3F4A;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 4px;
}
.data-cell-value-main {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}
.data-cell-value {
    height: 51px;
    color: #3C3F4A;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 55px;
}
.chart-main {
    box-sizing: border-box;
    border: 1px solid rgba(36,49,63,0.12);
    border-radius: 5px;
    background-color: #FFFFFF;
    margin-bottom: 25px;
}
.contact-list-main {
    width: 90%;
    margin-left: 30px;
    margin-right: 30px;
}
.contact-list-header {
    display: flex;
    /* justify-content: space-around; */
    border-bottom: 2px solid #1A253A;
    color: #1A253A;
    height: 50px;
    align-items: center;
}
.contact-list {
    display: flex;
    color: rgba(46, 48, 55, 0.7);
    height: 50px;
    align-items: center;
}

.contact-list:hover {
    box-shadow: 0 0.5rem 1.2rem rgb(0 0 0 / 20%);
}
.contacts-plus-icon {
    position: absolute;
    bottom: 60px;
    left: 140px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 50%;
}
.contacts-plus-icon:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
}
.contact-list-row-edit-icon-span {
    width: 8%;
}
.contact-list-row-edit-icon:hover {
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.contact-list-row-delete-icon:hover {
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.contact-list-header-name {
    margin-left: 5%;
    width: 30%;
}
.contact-list-header-number {

}
.contact-list-row-name {
    margin-left: 5%;
    width: 30%;
}
.contact-list-row-number {
    width: 50%;
}
.pagination-div {
    display: flex;
    align-items: flex-end;
    margin-top: 30px;
}
