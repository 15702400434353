.other-message-cube-main {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #2ec2fe;
    background-color: #f5fcff;
    width: max-content;
    max-width: 70%; 
    padding: 1.25rem 1.25rem 1rem 1.25rem;
    margin-right: auto;
    margin-bottom: 1rem;

    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    transform: rotate(180deg);
    direction: ltr;
}
.other-message-cube-name-and-time {
    display: flex;
    justify-content: space-between;
}
.other-message-cube-name {
    font-size: 14px;
    font-weight: bold;
    color: #3c3f4a;
}
.other-message-cube-time {
    font-size: 12px;
    color: #8a8c92;
    margin-left: 1rem;
}
.other-message-cube-content {
    font-size: 14px;
    color: #3c3f4a;
    margin-top: 0.5rem;
}
.other-message-cube-content-text {
    word-wrap: break-word;
}
.message-link {
    color: #72a3d8;
}