.cell-content {
    height: 77px;
    padding: 18px 25px 17px 15px;
    margin: 26px 15px 0 26px;
    border-radius: 5px;
    border: solid 1px rgba(26, 37, 58, 0.06);
    background-color: #fbfbfb;
    font-size: 14px;
}

.i-name {
    font-weight: bold;
}

.i-button {
    width: 250px;
}

.crm-modal {
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    color: black;
    font-size: 12px;
}

.crm-save {
    margin-top: 43px;
    border: none;
    width: 100%;
}

.img>img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}
.crm-integration-cell-login-or-update-btn {
    margin-left: 0.938rem;
}
.crm-integration-cell-personal-user-settings-btn {
    font-size: 14px;
}